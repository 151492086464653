import {
  Color,
  CzmlDataSource,
  SensorVolumePortionToDisplay,
} from "@cesium/engine";

/**
 * @private
 */
export default function processCommonSensorProperties(
  sensor,
  sensorData,
  interval,
  sourceUri,
  entityCollection,
) {
  CzmlDataSource.processPacketData(
    Boolean,
    sensor,
    "show",
    sensorData.show,
    interval,
    sourceUri,
    entityCollection,
  );
  CzmlDataSource.processPacketData(
    Number,
    sensor,
    "radius",
    sensorData.radius,
    interval,
    sourceUri,
    entityCollection,
  );
  CzmlDataSource.processPacketData(
    Boolean,
    sensor,
    "showIntersection",
    sensorData.showIntersection,
    interval,
    sourceUri,
    entityCollection,
  );
  CzmlDataSource.processPacketData(
    Color,
    sensor,
    "intersectionColor",
    sensorData.intersectionColor,
    interval,
    sourceUri,
    entityCollection,
  );
  CzmlDataSource.processPacketData(
    Number,
    sensor,
    "intersectionWidth",
    sensorData.intersectionWidth,
    interval,
    sourceUri,
    entityCollection,
  );
  CzmlDataSource.processPacketData(
    Boolean,
    sensor,
    "showThroughEllipsoid",
    sensorData.showThroughEllipsoid,
    interval,
    sourceUri,
    entityCollection,
  );
  CzmlDataSource.processMaterialPacketData(
    sensor,
    "lateralSurfaceMaterial",
    sensorData.lateralSurfaceMaterial,
    interval,
    sourceUri,
    entityCollection,
  );
  CzmlDataSource.processPacketData(
    Boolean,
    sensor,
    "showLateralSurfaces",
    sensorData.showLateralSurfaces,
    interval,
    sourceUri,
    entityCollection,
  );
  CzmlDataSource.processMaterialPacketData(
    sensor,
    "ellipsoidSurfaceMaterial",
    sensorData.ellipsoidSurfaceMaterial,
    interval,
    sourceUri,
    entityCollection,
  );
  CzmlDataSource.processPacketData(
    Boolean,
    sensor,
    "showEllipsoidSurfaces",
    sensorData.showEllipsoidSurfaces,
    interval,
    sourceUri,
    entityCollection,
  );
  CzmlDataSource.processMaterialPacketData(
    sensor,
    "ellipsoidHorizonSurfaceMaterial",
    sensorData.ellipsoidHorizonSurfaceMaterial,
    interval,
    sourceUri,
    entityCollection,
  );
  CzmlDataSource.processPacketData(
    Boolean,
    sensor,
    "showEllipsoidHorizonSurfaces",
    sensorData.showEllipsoidHorizonSurfaces,
    interval,
    sourceUri,
    entityCollection,
  );
  CzmlDataSource.processMaterialPacketData(
    sensor,
    "domeSurfaceMaterial",
    sensorData.domeSurfaceMaterial,
    interval,
    sourceUri,
    entityCollection,
  );
  CzmlDataSource.processPacketData(
    Boolean,
    sensor,
    "showDomeSurfaces",
    sensorData.showDomeSurfaces,
    interval,
    sourceUri,
    entityCollection,
  );
  CzmlDataSource.processPacketData(
    SensorVolumePortionToDisplay,
    sensor,
    "portionToDisplay",
    sensorData.portionToDisplay,
    interval,
    sourceUri,
    entityCollection,
  );
  CzmlDataSource.processPacketData(
    Boolean,
    sensor,
    "environmentConstraint",
    sensorData.environmentConstraint,
    interval,
    sourceUri,
    entityCollection,
  );
  CzmlDataSource.processPacketData(
    Boolean,
    sensor,
    "showEnvironmentOcclusion",
    sensorData.showEnvironmentOcclusion,
    interval,
    sourceUri,
    entityCollection,
  );
  CzmlDataSource.processMaterialPacketData(
    sensor,
    "environmentOcclusionMaterial",
    sensorData.environmentOcclusionMaterial,
    interval,
    sourceUri,
    entityCollection,
  );
  CzmlDataSource.processPacketData(
    Boolean,
    sensor,
    "showEnvironmentIntersection",
    sensorData.showEnvironmentIntersection,
    interval,
    sourceUri,
    entityCollection,
  );
  CzmlDataSource.processPacketData(
    Color,
    sensor,
    "environmentIntersectionColor",
    sensorData.environmentIntersectionColor,
    interval,
    sourceUri,
    entityCollection,
  );
  CzmlDataSource.processPacketData(
    Number,
    sensor,
    "environmentIntersectionWidth",
    sensorData.environmentIntersectionWidth,
    interval,
    sourceUri,
    entityCollection,
  );
  CzmlDataSource.processPacketData(
    Boolean,
    sensor,
    "showViewshed",
    sensorData.showViewshed,
    interval,
    sourceUri,
    entityCollection,
  );
  CzmlDataSource.processPacketData(
    Color,
    sensor,
    "viewshedVisibleColor",
    sensorData.viewshedVisibleColor,
    interval,
    sourceUri,
    entityCollection,
  );
  CzmlDataSource.processPacketData(
    Color,
    sensor,
    "viewshedOccludedColor",
    sensorData.viewshedOccludedColor,
    interval,
    sourceUri,
    entityCollection,
  );
  CzmlDataSource.processPacketData(
    Number,
    sensor,
    "viewshedResolution",
    sensorData.viewshedResolution,
    interval,
    sourceUri,
    entityCollection,
  );
}
